export const step = [
  {
    title: '阅读须知',
    name: 'read',
    permission: 'IEBDC:SQ:YDXZ',
  },
  // {
  //   title: '申请类型',
  //   name: 'applicantTypeContainer',
  //   permission: 'IEBDC:SQ:DJLX1' // 申请类型为新设计合并流程，权限暂时用之前的登记类型
  // },
  {
    title: '产权证核验',
    name: 'rightCertify',
    permission: 'IEBDC:SQ:CQZHY',
    code: [
      'N200104',
      'N300104',
      'N500104',
      'N400104',
      'N400301',
      'N100301',
      'H100311',
      'N200301',
      'N300301',
      'N300126',
      'N360099',
      'N360000',
      'N100203',
      'H999003', //合并流程（变更+转移）
      // 'H999002', //合并流程（转移+抵押）
      'N200111',
      'N100303',
      'N300099',
      'H999001', // 合并流程（预告买卖+预告抵押）]
      'N100106', // 宅基地使用权及房屋所有权首次登记
      'N200106', // 宅基地使用权及房屋所有权转移登记
      'N300106', // 宅基地使用权及房屋所有权变更登记
      'N500106', // 宅基地使用权及房屋所有权更正登记
      'N400106', // 宅基地使用权及房屋所有权注销登记
      'N600106', // 宅基地使用权及房屋所有权补正登记
      'N700106', // 宅基地使用权及房屋所有权换证登记
      'N200105', // 宅基地所有权转移登记
      'N300105', // 宅基地所有权变更登记
      'N500105', // 宅基地所有权更正登记
      'N400105', // 宅基地所有权注销登记
      'N600105', // 宅基地所有权补正登记
      'N700105', // 宅基地所有权换证登记
      'N100201',
      'F400200', // 土地承包经营权
      'F500200', // 土地经营权
      'N510001', // 林地所有权首次登记
      'N510002', // 林地所有权转移登记
      'N510003', // 林地所有权更正登记
      'N510004', // 林地所有权变更登记
      'N510005', // 林地所有权注销登记
      'H999006', // 房屋所有权转移登记+抵押权变更登记（同行带押过户）
      'N610001', // 林地使用权/森林、林木使用权首次登记
      'N610002', // 林地使用权/森林、林木使用权转移登记
      'N610003', // 林地使用权/森林、林木使用权更正登记
      'N610004', // 林地使用权/森林、林木使用权变更登记
      'N610005', // 林地使用权/森林、林木使用权注销登记
      'N710001', // 林地承包经营权及林木所有权首次登记
      'N710002', // 林地承包经营权及林木所有权转移登记
      'N710003', // 林地承包经营权及林木所有权更正登记
      'N710004', // 林地承包经营权及林木所有权变更登记
      'N710005', // 林地承包经营权及林木所有权注销登记
      'N810001', // 林地经营权及林木所有权首次登记
      'N810002', // 林地经营权及林木所有权转移登记
      'N810003', // 林地经营权及林木所有权更正登记
      'N810004', // 林地经营权及林木所有权变更登记
      'N810005', // 林地经营权及林木所有权补证换证登记
      'N810006', // 林地经营权及林木所有权注销登记
      'N910001', // 林地使用权/林木所有权首次登记
      'N910002', // 林地使用权/林木所有权转移登记
      'N910003', // 林地使用权/林木所有权更正登记
      'N910004', // 林地使用权/林木所有权变更登记
      'N910005', // 林地使用权/林木所有权注销登记
    ],
    areaCode: ['654026', '654025'],
  },
  {
    title: '楼盘表提取',
    name: 'showHouseTable',
    permission: 'IEBDC:SQ:LPBTQ',
    // code: ['N100401', 'N100201', 'N100105','N100104'] // N100105宅基地使用权首次登记
    code: ['N100104'],
    areaCode: ['654026', '654025'],
    companyCode: ['N100201', 'N100203', 'N100104', 'N200104', 'N10020301', 'H999001', 'H999002', 'H100203'], // 企业用户使用楼盘表
  },
  {
    title: '填写申请表',
    name: 'applicantTable',
    permission: 'IEBDC:SQ:TXSQB',
  },
  //   {
  //     title: '信息核验',
  //     name: 'intelligentAudit',
  //     permission: 'IEBDC:SQ:ZNSP',
  //     code: [
  //       'N200104', //房屋所有权转移登记
  //       'N300104', //房屋变更
  //       'N500104', //房屋更正
  //       'N400104', //注销登记
  //       //'N400301', //抵押权注销登记
  //       'N100301', //抵押权首次登记
  //       'N200301', //房屋抵押权转移登记
  //       'N300301', //房屋抵押权变更登记
  //       'N300126', //预售商品房抵押权预告注销
  //       //'N360099',
  //       'N360000', //预售商品房买卖预告注销
  //       'N100203', //预售商品房抵押权预告登记
  //       'H999003', //合并流程（变更+转移）
  //       'H999002', //合并流程（转移+抵押）
  //       'N200111', //土地押权首次登记
  //       'N200112', //土地押权转移登记
  //       //'N100303', //土地押权注销登记
  //       //'N300099',
  //       'H999001', //预告登记+预抵押登记
  //       'N100401', //房屋首次登记
  //       'N100201', //预售商品房买卖预告登记
  //       'N100211', //二手房买卖预告登记
  //       'N100106', // 宅基地使用权及房屋所有权首次登记
  //       'N200106', // 宅基地使用权及房屋所有权转移登记
  //       'N300106', // 宅基地使用权及房屋所有权变更登记
  //       'N500106', // 宅基地使用权及房屋所有权更正登记
  //       'N400106', // 宅基地使用权及房屋所有权注销登记
  //       'N600106', // 宅基地使用权及房屋所有权补正登记
  //       'N700106', // 宅基地使用权及房屋所有权换证登记
  //       'N100105', // N100105宅基地使用权首次登记
  //       'N200105', // 宅基地所有权转移登记
  //       'N300105', // 宅基地所有权变更登记
  //       'N500105', // 宅基地所有权更正登记
  //       'N400105', // 宅基地所有权注销登记
  //       'N600105', // 宅基地所有权补正登记
  //       'N700105' // 宅基地所有权换证登记
  //     ]
  //   },
  {
    title: '身份认证',
    name: 'authorizedCertify',
    permission: 'IEBDC:SQ:SQRZ',
    code: ['N200104'],
  },
  {
    title: '材料上传',
    name: 'uploadFile',
    permission: 'IEBDC:SQ:SCFJ',
  },
  // {
  //   title: '办理方式',
  //   name: 'expressFile',
  //   permission: 'IEBDC:SQ:SCYJ',
  //   areaCode: ['653126'],
  // },
  {
    title: '完成',
    name: 'finish',
    permission: 'IEBDC:SQ:WC',
  },
];
