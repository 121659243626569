// export const GLOBAL_CONFIG = window.CONFIG; // 来自 public/config.js 中
import { crypto } from '@iebdc/utils/crypto';
const DES = (config) => {
  const config_key = ['code', 'title', 'APP_INFO'];
  for (let key in config) {
    if (config_key.indexOf(key) < 0 && typeof config[key] === 'string') {
      config[key] = crypto.decryptByDES(config[key]) || config[key];
    }
  }
  return config;
};
let config = window.CONFIG; // 来自 public/config.js中
if (window.CONFIG && window.CONFIG.unify_ip.indexOf('http') < 0) {
  config = DES(Object.assign({}, window.CONFIG));
}
// console.log(crypto.decryptByDES('Z99BYjC7tPD0+7m3yXg4i7KR6PHZLR9AgjVBNCzYvsWKCGA3MmrFPHAedBI08YKhT3nMei0mgy5o7MC9VIxtlqbi6L318JQTOHyip3gUeVKTtJ8lEbLgFDfZE8+6JTf9TN0fBrqX20M='));
// console.log(crypto.encryptByDES('https://tpass99.xinjiang.chinatax.gov.cn:8443/api/v1.0/auth/oauth2/goveCodeUrl?client_id=jp667bb7pfee44e5a8ejac4pf89e45fb&funcId=GN00000140000078&type=3'));

// https://tpass(99).xinjiang.chinatax.gov.cn:8443/api/v1.0/auth/oauth2/goveCodeUrl?client_id=新电局WEB端应用id&funcId=功能标识或功能ID&type=3


window.CONFIG = config;
if (window.location.href.indexOf('localhost') > 0) {
  window.CONFIG.internet = window.CONFIG.internet.replace('https://www.xinjiangbdc.com.cn', '/apis')
  window.CONFIG.uums = window.CONFIG.uums.replace('https://www.xinjiangbdc.com.cn', '/apis')
  window.CONFIG.UUMS_SERVER = window.CONFIG.UUMS_SERVER.replace('https://www.xinjiangbdc.com.cn', '/apis')
  window.CONFIG.login = window.CONFIG.login.replace('https://www.xinjiangbdc.com.cn', '/apis')
  window.CONFIG.fms = window.CONFIG.fms.replace('https://www.xinjiangbdc.com.cn', '/apis')
}
export const GLOBAL_CONFIG = window.CONFIG; // 来自 public/config. js 中
export const REST_SERVICE = {
  zww: {
    login: GLOBAL_CONFIG.zww + 'rest/oauth2/token',
    login1: GLOBAL_CONFIG.zww + 'rest/oauth2/authorize',
    logout: GLOBAL_CONFIG.zww + 'rest/oauth2/logout',
  },
  login: {
    token: GLOBAL_CONFIG.login + 'checkTokenByAppKey', // 获取token
    login: GLOBAL_CONFIG.login + 'login', // 登录
    loginUk: GLOBAL_CONFIG.login + 'caLogin', // ukey登录
    logout: GLOBAL_CONFIG.login + 'logout', // 登出
    getCode: GLOBAL_CONFIG.login + 'getCode?' + Math.random(), // 获取验证码
  },
  uums: {
    bsdtDetail: GLOBAL_CONFIG.internet + 'workOfficeController/findByOfficeCode', //通过办事大厅代码（officeCode）查询办事大厅详情
    getDicInfo: GLOBAL_CONFIG.uums + 'dict/tree', // 获取字典
    getOrganBsdt: GLOBAL_CONFIG.uums + 'organization/tree', // 获取组织机构信息
    area: GLOBAL_CONFIG.uums + 'area/tree', // 获取辖区信息
    areaUserPermission: GLOBAL_CONFIG.uums + 'permission/getPermByAreaCode', //获取区县 和用户的权限交集
    findByDjjg: GLOBAL_CONFIG.internet + 'workOfficeController/findByDjjg', // 通过登记机构查询办事大厅列表
    getWorkOffice: GLOBAL_CONFIG.internet + 'workOfficeController/getWorkOffice', // 查询办事大厅
    getOrgsByAreaId: GLOBAL_CONFIG.uums + 'organization/getOrgsByAreaId', //查找机构
  },
  //统一登陆相关
  unified: {
    //通过返回的author获取用户信息
    getAccessToken: GLOBAL_CONFIG.internet + 'userInfoController/loginByTicket',
    zwLoginByToken: GLOBAL_CONFIG.internet + 'zwfw/zwLoginByToken',
  },
  isblack: GLOBAL_CONFIG.internet + 'userInfoController/inBlackList', //是否在黑名单内
  user: {
    findUserName: GLOBAL_CONFIG.internet + 'userInfoController/findUserName', //查找用户
    getRandom: GLOBAL_CONFIG.internet + 'userInfoController/generateRandomValue', // 根据用户名获取随机数
    updataPwd: GLOBAL_CONFIG.internet + 'userInfoController/modifyUserinfoPwd', //修改用户密码
    updataPhone: GLOBAL_CONFIG.internet + 'userInfoController/modifyUserinfoPhone', //修改用户电话号码
    updateFinacialInfo: GLOBAL_CONFIG.internet + 'userInfoController/updateFinacialInfo',
    updateRealNameStatus: GLOBAL_CONFIG.internet + 'userInfoController/updateRealNameStatus', //提示用户实名认证状态
  },
  verfiry: {
    download: GLOBAL_CONFIG.internet + 'uploadfileConfigController/getDecteServerExe', // 下载加密狗插件
    checkKey: GLOBAL_CONFIG.internet + 'userInfoController/checkSecretKey', // 后台验证加密狗
    changePsw: GLOBAL_CONFIG.internet + 'userInfoController/modifyUserinfoByTel',
    checkKeyByHn: GLOBAL_CONFIG.internet + 'userInfoController/checkKeyByHn', //校验海南信息中心uk秘钥
  },
  //注册服务
  register: {
    getWorkOffice: GLOBAL_CONFIG.internet + 'workOfficeController/getWorkOffice',
    rggr: GLOBAL_CONFIG.internet + 'userInfoController/registerUserinfo',
    rgqy: GLOBAL_CONFIG.internet + 'userInfoController/registerInstitutionalUser',
    getCode: GLOBAL_CONFIG.internet + 'thirdServiceController/sendAuthCodeMsg', //发送短信验证
    exsitGrLoginName: GLOBAL_CONFIG.internet + 'userInfoController/existsUserByNameOrPhone', //判断个人用户是否存在
    exsitJgLoginName: GLOBAL_CONFIG.internet + 'userInfoController/existsIntitutionalUserLoginName', //判断企业用户是否存在
    findUser: GLOBAL_CONFIG.internet + 'userInfoController/findUser',
    protocol: GLOBAL_CONFIG.unify_ip + 'internet-estate-web/yhxy-8af19.pdf', // 注册协议书
    getEwm: GLOBAL_CONFIG.internet + 'userInfoController/getTwoDimensionalCode', //获取实名认证二维码
    grrz: GLOBAL_CONFIG.internet + 'userInfoController/authUserinfo',
    qyrz: GLOBAL_CONFIG.internet + 'userInfoController/authInstitutionalUser',
    institutionalApprove: GLOBAL_CONFIG.internet + 'userInfoController/institutionalApprove', //企业认证
  },
  role: {
    getRole: GLOBAL_CONFIG.uums + 'role/get', //获取权限列表
  },
  app: {
    getAppInfo: GLOBAL_CONFIG.uums + 'app/get', //app详情
  },
  userInfo: {
    getUserInfo: GLOBAL_CONFIG.uums + 'user/get', //获取用户详情
  },
  //文件服务
  files: {
    mulFileSave: GLOBAL_CONFIG.fms + 'file/uploadFiles', //文件上传
    fileDetail: GLOBAL_CONFIG.internet + 'fileUploadController/viewUploadFile', //查看上传材料
    delFileById: GLOBAL_CONFIG.fms + 'file/delFileById', //删除单个文件
    fileInfo: GLOBAL_CONFIG.fms + 'file/findFileById', //根据id查询文件详情
    getUploadFile: GLOBAL_CONFIG.internet + 'fileUploadController/getUploadFiles', //根据业务号（ywh）查询已上传材料信息
    saveFileUploadInfo: GLOBAL_CONFIG.internet + 'fileUploadController/saveFileUploadInfo', //保存文件上传信息
    deleteFile: GLOBAL_CONFIG.internet + 'fileUploadController/deleteUploadFile', //根据id删除上传的文件
    deleteFileByIdList: GLOBAL_CONFIG.internet + 'fileUploadController/deleteUploadFileByIdList', //根据id集合批量删除上传的文件
    getFileListByIdList: GLOBAL_CONFIG.internet + 'fileUploadController/getFileListByIdList', //根据文件id批量获取文件信息
    getUploadFilesCurrent: GLOBAL_CONFIG.internet + 'fileUploadController/getUploadFilesCurrent', //获取已上传文件
    getPluginFileByName: GLOBAL_CONFIG.internet + 'uploadfileConfigController/getPluginFileByName', //获取手写板插件
    getNoSignFileList: GLOBAL_CONFIG.internet + 'estatePrintController/getNoSignFileList',
  },
  // 热门服务
  hotService: {
    // 我要预约
    appointment: {
      getNewYyysl: GLOBAL_CONFIG.internet + 'api/appointment/getNewAppointmentCount',
      getSjdByOfficeCode: GLOBAL_CONFIG.internet + 'workOfficeController/getSjdByOfficeCode', //根据办事大厅获取时间段列表
      getYyxxConfigByDjjg: GLOBAL_CONFIG.internet + 'api/appointment/getYyxxConfigByDjjg', // 根据登记机构获取预约配置前三级
      showGuide: GLOBAL_CONFIG.internet + 'api/appointment/getNewFlowGuidanceBySubcfgId',
      showFiles: GLOBAL_CONFIG.internet + 'api/appointment/getNewUploadFilesBySubcfgId',
      getWindowCode: GLOBAL_CONFIG.internet + 'api/workOffice/getWindowCodeByOfficeCodeAndSubflowCode', //获取办事大厅窗口号
      saveAppointment: GLOBAL_CONFIG.internet + 'api/appointment/addNewAppointmentInfo',
    },
  },
  // 上门服务
  visitingService: {
    getYysx: GLOBAL_CONFIG.internet + 'api/process/getSubFlowList', // 获取预约事项
    initiate: GLOBAL_CONFIG.internet + 'api/doorToDoor/initiate', //保存上门预约信息
  },
  //流程配置服务
  guide: {
    query: GLOBAL_CONFIG.internet + 'flowDefController/getFlowDefConfig', //获取流程配置
    getFirstFlow: GLOBAL_CONFIG.internet + 'flowDefController/getFlowDefConfig', //根据登记机构、不动产类别获取流程配置前二级
    getThirdFlow: GLOBAL_CONFIG.internet + 'flowDefController/getFlowSubDefConfig', //根据二级流程定义id和流程类型查询三级流程，分页
    showGuide: GLOBAL_CONFIG.internet + 'flowDefController/getFlowGuidanceBySubcfgId', //根据三级流程id查询办事指南
    //showYyGuide: GLOBAL_CONFIG.internet + 'api/appointment/getNewFlowGuidanceBySubcfgId',
    showGuideByCode: GLOBAL_CONFIG.internet + 'flowDefController/getFlowGuidanceBySubcfgCode', //根据三级流程code查询办事指南
    showFiles: GLOBAL_CONFIG.internet + 'flowDefController/getUploadFilesBySubcfgId', //根据三级流程id查询材料列表
    //showYyFiles: GLOBAL_CONFIG.internet + 'api/appointment/getNewUploadFilesBySubcfgId',
  },
  //办事大厅
  hall: {
    query: GLOBAL_CONFIG.internet + 'workOfficeController/getWorkOffice', //查询办事大厅列表
    queryById: GLOBAL_CONFIG.internet + 'workOfficeController/getWorkOfficeById', //通过办事大厅id查询办事大厅列表
    addHall: GLOBAL_CONFIG.internet + 'workOfficeController/saveOrUpdateWorkOffice', //增加办事大厅
    deleteHall: GLOBAL_CONFIG.internet + 'workOfficeController/deleteWorkOffice', //删除办事大厅
    getTimeList: GLOBAL_CONFIG.internet + 'workOfficeController/getSjdByOfficeCode', //根据办事大厅获取时间段列表
    addTimeList: GLOBAL_CONFIG.internet + 'workOfficeController/addWorkOfficeSjd', //保存时间段
    deleteTimeList: GLOBAL_CONFIG.internet + 'workOfficeController/deleteSjdById', //根据时间段id删除时间段
    windowConfig: GLOBAL_CONFIG.internet + 'api/workOffice/windowConfig', //增加窗口
    deleteWindow: GLOBAL_CONFIG.internet + 'api/workOffice/deleteWindow', //删除窗口管理数据
    configSubFlowList: GLOBAL_CONFIG.internet + 'api/workOffice/configSubFlowList', //绑定流程
    updateWorkOfficeYyconfig: GLOBAL_CONFIG.internet + 'workOfficeController/updateWorkOfficeYyconfig', //在大厅根据预约事项设置每个预约大类对应的数量
    waitTimeConfig: GLOBAL_CONFIG.internet + 'api/workOffice/waitTimeConfig', //更新内外网数据推送时间
    getSystemTime: GLOBAL_CONFIG.internet + 'uumsController/getSystemTime',
    getFileList: GLOBAL_CONFIG.internet + 'flowDefController/getUploadFilesBySubcfgCode', //根据三级流程code，查询材料列表
    getSystemDic: GLOBAL_CONFIG.internet + 'uumsController/getDictTree',
  },
  // 我的申请
  myApplication: {
    getProcessInfoPageList: GLOBAL_CONFIG.internet + 'api/registerflow/getProcessInfoPageList',
    deleteProcessInfo: GLOBAL_CONFIG.internet + 'api/registerflow/deleteProcessInfo', //删除申请
    retractApply: GLOBAL_CONFIG.internet + 'api/registerflow/rejectProcess', //撤销流程信息
    getAppointmentInfoByCode: GLOBAL_CONFIG.internet + 'api/appointment/getAppointmentInfoByCode', //获取/查看预约信息
    // cancelAppointment
    getGuideInfo: GLOBAL_CONFIG.internet + 'api/appointment/getNewFlowGuidanceBySubcfgId', //根据id查询办事指南
    getMaterialsInfo: GLOBAL_CONFIG.internet + 'api/appointment/getNewUploadFilesBySubcfgId', //根据id查询材料列表
    cancelAppointment: GLOBAL_CONFIG.internet + 'api/appointment/cancelAppointment', // 取消预约
    getProcessInfo: GLOBAL_CONFIG.internet + 'api/registerflow/getProcessInfo', //获取我的申请信息
    getAddressByUserId: GLOBAL_CONFIG.internet + 'userOperatorController/getAddressByUserId', //通过id获取地址信息
    findByYwhAndPostType: GLOBAL_CONFIG.internet + 'api/express/findByYwhAndPostType', //通过业务号获取快递方式
    getRightHolder: GLOBAL_CONFIG.internet + 'api/registerflow/getRightHolder', //获取权利人详情
    getObligor: GLOBAL_CONFIG.internet + 'api/registerflow/getObligor', //获取义务人详情
    saveOrUpdateAddress: GLOBAL_CONFIG.internet + 'userOperatorController/saveOrUpdateAddress', //设置默认地址
    deleteAddress: GLOBAL_CONFIG.internet + 'userOperatorController/deleteAddress', //删除地址
    getPageUrl: GLOBAL_CONFIG.internet + 'evaluateController/getPageUrl', // 获取评价系统跳转链接
    saveEvaluateStatus: GLOBAL_CONFIG.internet + 'evaluateController/saveEvaluateStatus', // 评价状态维护
  },
  //我的税务
  myTax: {
    queryTaxFinish: GLOBAL_CONFIG.internet + 'api/taxService/queryTaxFinish',
    getTaxInfoList: GLOBAL_CONFIG.internet + 'api/taxService/getTaxInfoList',
    getTaxQrCode: GLOBAL_CONFIG.internet + 'integration/api/genPayQrCode',
    pushTax: GLOBAL_CONFIG.internet + 'integration/api/pushTax',
    quryJkxxOrBhxx: GLOBAL_CONFIG.internet + 'integration/api/quryJkxxOrBhxx',
  },
  //下载
  download: {
    downloadExe: GLOBAL_CONFIG.internet + 'uploadfileConfigController/getMonitorExe', //获取摄像头控件的文件流
    wdsqExcel: GLOBAL_CONFIG.internet + 'api/registerflow/exportProcessInfoExcel', //我的申请导出流程信息
  },
  // 我的申请
  myAppointment: {
    getAppointmentPageList: GLOBAL_CONFIG.internet + 'api/appointment/getAppointmentPgaeList',
  },
  //代理人
  dlr: {
    addAgent: GLOBAL_CONFIG.internet + 'userInfoController/addAgent', //用户中心添加代理人
    getAgentsByUserId: GLOBAL_CONFIG.internet + 'userInfoController/getAgentsByUserId', //用户中心根据用户id获取代理人列表
    deleteAgentById: GLOBAL_CONFIG.internet + 'userInfoController/deleteAgentById', //用户中心根据用户id删除代理人
    updateAgent: GLOBAL_CONFIG.internet + 'userInfoController/updateAgent', //用户中心修改代理人
  },
  //经办人
  jbr: {
    getDigitalAgentList: GLOBAL_CONFIG.internet + 'userInfoController/getDigitalAgentList', //经办人列表
    updateDigitalAgent: GLOBAL_CONFIG.internet + 'userInfoController/updateDigitalAgent', //更新经办人
    addDigitalAgent: GLOBAL_CONFIG.internet + 'userInfoController/addDigitalAgent', //新增经办人
    deleteDigitalAgent: GLOBAL_CONFIG.internet + 'userInfoController/deleteDigitalAgent', //删除经办人
    getDigitalAgentRole: GLOBAL_CONFIG.internet + 'userInfoController/getDigitalAgentRole', //获取经办人的角色
    getAgentUserList: GLOBAL_CONFIG.internet + 'userInfoController/getAgentUserList', //根据当前登录的姓名和证件号获取办
    getAgentUserQyUser: GLOBAL_CONFIG.internet + 'userInfoController/getAgentUserQyUser', //选择对应的经办人企业信息
    getAgentUserByQyId: GLOBAL_CONFIG.internet + 'userInfoController/getAgentUserByQyId', //根据企业id获取企业的经办人信
  },
  // 我的上门
  myVisit: {
    listByOptions: GLOBAL_CONFIG.internet + 'api/doorToDoor/listByOptions', //查询上门服务申请列表
    cancel: GLOBAL_CONFIG.internet + 'api/doorToDoor/cancel', //取消上门服务申请
    visitInfoDetailsById: GLOBAL_CONFIG.internet + 'api/doorToDoor/detailsById', //根据id查看上门服务信息详情
  },
  // 我的查询
  myQuery: {
    getCheckCertifies: GLOBAL_CONFIG.internet + 'checkCertifyController/getCheckCertifies',
    // getCertifiesResult: GLOBAL_CONFIG.internet + '/checkCertifyController/getCertifiesResult', // 证书查验结果查询
    printQszm: GLOBAL_CONFIG.internet + 'estatePrintController/printQszm', // 打印权属证明
    printQszmWithSignature: GLOBAL_CONFIG.internet + 'estatePrintController/printQszmWithSignature', //打印权属证明带签章
    cancelCheckCertifies: GLOBAL_CONFIG.internet + 'checkCertifyController/cancelCheckCertifies', // 取消查询权属证明
    printQszmyh: GLOBAL_CONFIG.internet + 'estatePrintController/printQszmyh', // 打印权属证明银行
    getQszmHtml: GLOBAL_CONFIG.internet + 'estatePrintController/getQszmHtml', // 获取权属证明html
    reviewFwcd: GLOBAL_CONFIG.internet + 'registerController/reviewFwcd', // /registerController/reviewFwcd
    printYfqszm: GLOBAL_CONFIG.internet + 'estatePrintController/printYfzmWithSignature', // 打印有房权属证明printYfzmWithSignature
    printWfqszm: GLOBAL_CONFIG.internet + 'estatePrintController/printWfzmWithSignature', // 打印无房权属证明
    getFjclByYwh: GLOBAL_CONFIG.internet + 'dataExchangeController/getFjclByYwh', // 根据业务号或取房产查询附件信息
    printQlxxPay: GLOBAL_CONFIG.internet + 'estatePrintController/printQlxxPay', // 打印缴费单
    printOthers: GLOBAL_CONFIG.internet + 'estatePrintController/printOthers', // 打印他人权属证明查询
    printDyht: GLOBAL_CONFIG.internet + 'estatePrintController/printDyht', // 打印抵押合同
    printQszmXml: GLOBAL_CONFIG.internet + 'estatePrintController/printQszmXml',
  },
  //文章服务
  policy: {
    getPolicyData: GLOBAL_CONFIG.internet + 'articleController/getArticleByQuery', //查询文章列表
    getPolicyDetail: GLOBAL_CONFIG.internet + 'articleController/viewArticle', //查看文章
    getfileType: GLOBAL_CONFIG.internet + 'articleController/getAllWzlx', //查询文章类型
  },
  //权属证明查询
  qszm: {
    // qszmCheck: GLOBAL_CONFIG.internet + 'checkCertifyController/checkCertifyInspect', //权属证明查询
    checkCertifyInspect: GLOBAL_CONFIG.internet + 'realEstateQueryController/getRealEstateInfo', // 新权属证明查询--个人
    queryCompanySearchShare: GLOBAL_CONFIG.internet + 'realEstateQueryController/queryCompanySearchShare', // 新权属证明查询--企业
    // checkCertifyInspectWeb: GLOBAL_CONFIG.internet + '/dataExchangeController/checkCertifyInspectWeb', // 5.8web和公众号查档证明
    cancelQuery: GLOBAL_CONFIG.internet + 'checkCertifyController/cancelCheckCertifies', //取消查询权属证明
    getMyQuery: GLOBAL_CONFIG.internet + 'checkCertifyController/getCheckCertifies', //查询权属证明
    getCertifiesResult: GLOBAL_CONFIG.internet + 'checkCertifyController/getCertifiesResult', //获取证书真名真伪记过
    getNewFwCdzm: GLOBAL_CONFIG.internet + 'dataExchangeController/getNewFwCdzm', //新版查档证明接口
    getNewFwCdzmDetail: GLOBAL_CONFIG.internet + 'dataExchangeController/getNewFwCdzmDetail', //新版查档证明打印接口
    getPermission: GLOBAL_CONFIG.internet + 'userInfoController/getPermission', //鉴权查档权限
  },
  //证书证明查询
  zszm: {
    // zszmCheck: GLOBAL_CONFIG.internet + 'checkCertifyController/certificateInspect', //证书证明查询
    // qszmzwCheck: GLOBAL_CONFIG.internet + 'checkCertifyController/getCertifiesTureOrNot', //权属证明真伪查询
    // checkCertifcateTrue: GLOBAL_CONFIG.internet + '/dataExchangeController/checkCertifcateTrue', // 不动产证书（证明）查验
    checkCertifcateTrueWeb: GLOBAL_CONFIG.internet + 'dataExchangeController/checkCertifcateTrueWeb', // 5.9web和公众号_不动产证书（证明）查验
  },
  //进度查询
  jdcx: {
    // processCheck: GLOBAL_CONFIG.internet + 'api/registerflow/getProcessInfoByWwyyhAndTel' //获取流程详情（进度查询）
    getYwzt: GLOBAL_CONFIG.internet + 'dataExchangeController/getYwzt', // 不动产登记进度查询
    // getYwzWeb: GLOBAL_CONFIG.internet + '/dataExchangeController/getYwzWeb' // 5.10web和公众号不动产登记进度查询
  },
  //留言板
  lyb: {
    getGuestNoteList: GLOBAL_CONFIG.internet + 'userOperatorController/getGuestNoteList', //获取留言内容
    deleteGuestNote: GLOBAL_CONFIG.internet + 'userOperatorController/deleteGuestNote', //删除留言内容
    getGuestNoteBySingleQuery: GLOBAL_CONFIG.internet + 'userOperatorController/getGuestNoteBySingleQuery', //通过单条件获取内容
    saveGuestNote: GLOBAL_CONFIG.internet + 'userOperatorController/saveGuestNote', //保存留言内容
  },
  //电子证照
  dzzz: {
    saveELicense: GLOBAL_CONFIG.internet + 'digitalcert/api/saveELicense', //获取业务号
    getQuertResult: GLOBAL_CONFIG.internet + 'digitalcert/api/query', //获取查询电子证照结果
    saveUploadFileInfo: GLOBAL_CONFIG.internet + 'digitalcert/api/saveDzzzUploadFileInfo', //保存电子证照核验的文件
    getVerifyCeritificateResult: GLOBAL_CONFIG.internet + 'digitalcert/api/verify', //电子证照核验
    queryCeritificateList: GLOBAL_CONFIG.internet + 'digitalcert/api/querycertificatelist', // 2.5通过共享交换查询电子证照列表
    downloadFile: GLOBAL_CONFIG.internet + 'digitalcert/api/download', //电子证照下载
    translate: GLOBAL_CONFIG.internet + 'digitalcert/api/translate', //base64字符串转换
    verifyCeritificate: GLOBAL_CONFIG.internet + 'api/exchange/verifyCeritificate', // 2.4 电子证照查验
    downloadCeritificate: GLOBAL_CONFIG.internet + 'api/exchange/downloadCeritificate', // 2.3通过共享交换下载电子证照文件
  },
  //打印服务
  print: {
    printApply: GLOBAL_CONFIG.internet + 'estatePrintController/printApply', //打印申请表
    estatePrintApply: GLOBAL_CONFIG.internet + 'estatePrintController/estatePrintApply', //打印受理凭证
    printDyzm: GLOBAL_CONFIG.fms + 'file/findFileById', //打印抵押证明、电子证照
    printCQZM: GLOBAL_CONFIG.internet + 'checkCertifyController/getCqzsPdf', // 打印产权证明
    printQyDjzs: GLOBAL_CONFIG.internet + 'estatePrintController/printQyDjzs', //打印企业登记证书/证明
    printNotSignApply: GLOBAL_CONFIG.internet + 'estatePrintController/printNotSignApply', //生成申请表
  },
  // 申请信息
  apply: {
    // 申请登记操作接口
    checkApply: GLOBAL_CONFIG.internet + 'registerController/checkApply', // 修改预审状态
    clearInvalidProcesses: GLOBAL_CONFIG.internet + 'registerController/clearInvalidProcesses', // 删除权利信息表中的非法数据
    completeApply: GLOBAL_CONFIG.internet + 'registerController/completeApply', // 完成申请
    getApplyById: GLOBAL_CONFIG.internet + 'registerController/getApplyById', // 获取指定ID的申请
    getApplyByYwh: GLOBAL_CONFIG.internet + 'registerController/getApplyByYwh', // 获取指定外网业务号的申请
    getDcbZHByBdcdyh: GLOBAL_CONFIG.internet + 'registerController/getDcbZHByBdcdyh', // 根据户的不动产单元号获取所在幢的信息
    getPassedApplies: GLOBAL_CONFIG.internet + 'registerController/getPassedApplies', // 登记申请数据列表
    getPreliminaries: GLOBAL_CONFIG.internet + 'registerController/getPreliminaries', // 预审申请列表
    getQlrsByZywh: GLOBAL_CONFIG.internet + 'registerController/getQlrsByZywh', // 根据主业务号获取权利人列表
    getSqrRzztByWwywh: GLOBAL_CONFIG.internet + 'registerController/getSqrRzztByWwywh', // 根据主业务号获取申请人的询问笔录/视频认证状态
    getYwrsByQlxxId: GLOBAL_CONFIG.internet + 'registerController/getYwrsByQlxxId', // 根据子业务号获取义务人列表
    saveSqxx: GLOBAL_CONFIG.internet + 'registerController/saveSqxx', // 保存申请信息
    sendMsgToQlrAndYwr: GLOBAL_CONFIG.internet + 'registerController/sendMsgToQlrAndYwr', // 向其他权利人义务人发送验证短信提醒
    getProcessInfo: GLOBAL_CONFIG.internet + 'api/registerflow/getProcessInfo', // 获取流程信息
    modifyProcessInfo: GLOBAL_CONFIG.internet + 'api/registerflow/modifyProcessInfo', // 修改流程信息
    getSqsEwm: GLOBAL_CONFIG.internet + 'api/registerflow/getSqsEwm', // 获取申请二维码
    droolsCheckGetByYwh: GLOBAL_CONFIG.internet + 'api/drools/droolsCheckGetByYwh', // 根据业务号获取审批记录
    completeApplyResult: GLOBAL_CONFIG.internet + 'registerController/completeApplyResult', //开始智能审批
    getlpbInfo: GLOBAL_CONFIG.internet + 'dataExchangeController/getBdcxxZddm', //根据宗地代码提取楼盘表数据
    getGroupByYwh: GLOBAL_CONFIG.internet + 'api/drools/getGroupByYwh', //根据业务号获取审批记录的结果
    fileUploadToQlxxStepByWwywh: GLOBAL_CONFIG.internet + 'registerController/fileUploadToQlxxStepByWwywh', //认证状态
    getFwxxByZddm: GLOBAL_CONFIG.internet + 'dataExchangeController/getFwxxByZddm', // 通过宗地代码获取所有自然幢号
    getZlist: GLOBAL_CONFIG.internet + 'dataExchangeController/getZList', // 获取幢列表
    getHList: GLOBAL_CONFIG.internet + 'dataExchangeController/getHList', // 获取幢列表
    saveUserIdPcbh: GLOBAL_CONFIG.internet + 'api/registerflow/saveUserIdPcbh', //新增获取批次号
    getUserIdPcbh: GLOBAL_CONFIG.internet + 'api/registerflow/getUserIdPcbh', //获取批次号列表
  },
  // 询问笔录接口
  inquiryRecordController: {
    getInquiryRecordByYwh: GLOBAL_CONFIG.internet + 'InquiryRecordController/getInquiryRecordByYwh', // 通过业务号和申请人id查询询问笔录详情
    save: GLOBAL_CONFIG.internet + 'InquiryRecordController/save', // 保存询问笔录信息
    printInquiryRecord: GLOBAL_CONFIG.internet + 'estatePrintController/printInquiryRecord', // 打印询问笔录信息
    getFiles: GLOBAL_CONFIG.fms + 'file/findFileById', //根据id查询文件详情
    getMainInquiryRecordByYwh: GLOBAL_CONFIG.internet + 'InquiryRecordController/getMainInquiryRecordByYwh', // 通过业务号和申请人id查询询问笔录详情
  },
  authorizationSearch: {
    authorizeAdd: GLOBAL_CONFIG.internet + 'api/authorize/authorizeAdd', //新增授权
    authorizeBankInspectList: GLOBAL_CONFIG.internet + 'api/authorize/authorizeBankInspectList', //银行权属证明查询(批量授权
    authorizeBankOneInspect: GLOBAL_CONFIG.internet + 'api/authorize/authorizeBankOneInspect', //银行权属证明查询(单个授权
    authorizeBankUserGet: GLOBAL_CONFIG.internet + 'api/authorize/authorizeBankUserGet', //获取企业用户
    authorizeExcelToUser: GLOBAL_CONFIG.internet + 'api/authorize/authorizeExcelToUser', //解析EXCEL表格用户数据(批量授权
    authorizeGetByPage: GLOBAL_CONFIG.internet + 'api/authorize/authorizeGetByPage', //分页获取授权信息
    exportQueryTemplete: GLOBAL_CONFIG.internet + 'api/authorize/exportQueryTemplete', // 导出查询模板
    exportQszmExcel: GLOBAL_CONFIG.internet + 'api/authorize/exportQszmExcel', // 导出查询结果数据
  },
  //上传附件模块
  uploadApply: {
    getProcessInfo: GLOBAL_CONFIG.internet + 'api/registerflow/getProcessInfo', //获取申请具体信息
    getEwm: GLOBAL_CONFIG.internet + 'fileUploadController/uploadFileFromPhone', //获取上传文件二维码
  },
  // 申请管理操作接口
  registerFlowController: {
    addObligor: GLOBAL_CONFIG.internet + 'api/registerflow/addObligor', // 申请管理中新增义务人
    addObligorBatch: GLOBAL_CONFIG.internet + 'api/registerflow/addObligorBatch', // 批量插入义务人
    modifyObligor: GLOBAL_CONFIG.internet + 'api/registerflow/modifyObligor', // 修改义务人信息
    deleteObligor: GLOBAL_CONFIG.internet + 'api/registerflow/deleteObligor', //删除义务人
    addRightHolder: GLOBAL_CONFIG.internet + 'api/registerflow/addRightHolder', // 申请管理中新增权利人
    addRightHolderBatch: GLOBAL_CONFIG.internet + 'api/registerflow/addRightHolderBatch', // 批量添加权利人
    judgeDuplicate: GLOBAL_CONFIG.internet + 'api/registerflow/judgeDuplicate', // 判断权利人义务人是否重复
    getRightHolder: GLOBAL_CONFIG.internet + 'api/registerflow/getRightHolder', // 根据权利人ID获取权利人信息
    getObligor: GLOBAL_CONFIG.internet + 'api/registerflow/getObligor', // 根据ID获取义务人信息
    modifyRightHolder: GLOBAL_CONFIG.internet + 'api/registerflow/modifyRightHolder', //修改、新增权利人信息
    deleteRightHolder: GLOBAL_CONFIG.internet + 'api/registerflow/deleteRightHolder', //删除权利人（申请人）信息
    saveQlxxExMh: GLOBAL_CONFIG.internet + 'api/registerflow/saveQlxxExMh', //保存权利扩展信息多户内容
    getHuInfo: GLOBAL_CONFIG.internet + 'registerController/getDcbZHByBdcdyh', //获取户信息
    unifiedAssignment: GLOBAL_CONFIG.internet + 'api/registerflow/unifiedAssignment', //统一赋值户信息
    copyDyrToZwr: GLOBAL_CONFIG.internet + 'api/registerflow/copyDyrToZwr', //同步为债务人
    saveTzr: GLOBAL_CONFIG.internet + 'api/registerflow/addTzr',
  },
  //设备
  device: {
    getInfoByDeviceBh: GLOBAL_CONFIG.internet + 'sense/getInfoByDeviceBh', //根据设备编号获取3分钟内最新的一条记录，且获取后即失效
    deviceRegister: GLOBAL_CONFIG.internet + 'sense/deviceRegister', //添加设备信息
    getDeviceList: GLOBAL_CONFIG.internet + 'sense/getDeviceList', //获取设备信息
    getUsageByDeviceId: GLOBAL_CONFIG.internet + 'sense/getUsageByDeviceId', //根据编号id获取设备使用次数
    deleteDeviceById: GLOBAL_CONFIG.internet + 'sense/deleteDeviceById', //通过id删除设备
    updateSenseDevice: GLOBAL_CONFIG.internet + 'sense/updateSenseDevice', //更新设备信息
    saveCompany: GLOBAL_CONFIG.internet + 'userInfoController/updateInstitutionalUser', //保存修改用户中心高拍仪型号的用户信息
  },
  //产权核验模块
  rightCertify: {
    checkBdcqxxByZh: GLOBAL_CONFIG.internet + 'dataExchangeController/checkBdcqxxByZh', //产权证核验
    getBdcxxByBdcqzh: GLOBAL_CONFIG.internet + 'dataExchangeController/getBdcxxByBdcqzh', //根据不动产权证编号获取不动产信息
    getTdBdcxxByBdcqzh: GLOBAL_CONFIG.internet + 'dataExchangeController/getTdBdcxxByBdcqzh', //根据土地不动产权证号获取不动产信息
    getBdcxxByDyzmh: GLOBAL_CONFIG.internet + 'dataExchangeController/geBdcxxByDyzmh', //根据抵押编号获取不动产信息
    getBdcxxByYgzmh: GLOBAL_CONFIG.internet + 'dataExchangeController/getBdcxxByYgzmh', //根据预告编号获取不动产信息
    getBdcxxByDyh: GLOBAL_CONFIG.internet + 'dataExchangeController/getBdcxxByDyh', //根据不动产单元号获取不动产信息(包含核验
    // getBdcxxByDyh: 'http://127.0.0.1:8008/testBoot/getBdcxxByDyh', //根据不动产单元号获取不动产信息(包含核验
    getBdcxxByHtbh: GLOBAL_CONFIG.internet + 'dataExchangeController/getBdcxxByHtbh', //根据合同编号获取不动产信息
    getBdcxxByYwh: GLOBAL_CONFIG.internet + 'dataExchangeController/getBbcxxByYwh', //根据业务号获取不动产信息
    getDyaxxByYwh: GLOBAL_CONFIG.internet + 'dataExchangeController/getDyaxxByYwh', //根据抵押编号获取不动产信息
    getYgxxByYwh: GLOBAL_CONFIG.internet + 'dataExchangeController/getYgxxByYwh', //根据不动产权证编号获取不动产信息
    savePrVerificationInfo: GLOBAL_CONFIG.internet + 'registerController/savePrVerificationInfo', //根据不动产权证编号获取不动产信息
    getQlrxxByBdcqzhAndBdcdyh: GLOBAL_CONFIG.internet + 'dataExchangeController/getQlrxxByBdcqzhAndBdcdyh', // 根据不动产权证号及不动产单元号提取权利人信息
  },
  // 我的邮寄
  express: {
    getPageList: GLOBAL_CONFIG.internet + 'api/express/getPageList', //条件查询快递信息列表
    findByYwhAndPostType: GLOBAL_CONFIG.internet + 'api/express/findByYwhAndPostType', // 根据外网业务号和邮寄类型获取邮寄信息详情
    saveExpressInfo: GLOBAL_CONFIG.internet + 'api/express/saveExpressInfo', // 保存快递订单信息
    findByOfficeCode: GLOBAL_CONFIG.internet + 'workOfficeController/findByOfficeCode', //通过办事大厅代码查询办事大厅
  },
  //家庭成员
  familyList: {
    editQlrFamilyList: GLOBAL_CONFIG.internet + 'api/registerflow/editQlrFamilyList', //编辑权利人家庭成员
    editYwrFamilyList: GLOBAL_CONFIG.internet + 'api/registerflow/editYwrFamilyList', //编辑义务人家庭成员
  },
  // 首页
  dashboard: {
    getRecordsByLoginName: GLOBAL_CONFIG.internet + 'api/statistics/getRecordsByLoginName',
  }, //个人中心 区域 的 在申请、已预约，已查询
  // 楼盘申请
  applybuild: {
    getPageList: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyCheckByPage', // 条件查询
    getApplyById: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyGetById', // 详情查看
    addApply: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyByKfs', // 新增申请
    applyCheck: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyCheck', // 审核
    delApply: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyDeleteById', // 删除
  },
  // 缴费申请
  payOnline: {
    payApply: GLOBAL_CONFIG.internet + 'payMutualController/payApply', //发起缴费申请
    selectPayApplyPageList: GLOBAL_CONFIG.internet + 'payMutualController/selectPayApplyPageList',
    selectByParams: GLOBAL_CONFIG.internet + 'paymentAreaController/selectByParams',
  },
  //税务对接

  ca: {
    loginByUkey: GLOBAL_CONFIG.internet + 'ca/loginByUkey',
  },
  ems: {
    createOrder: GLOBAL_CONFIG.internet + 'ems/create_order', //下单
    pickUp: GLOBAL_CONFIG.internet + 'ems/pick_up', // 上门揽件
    getOrders: GLOBAL_CONFIG.internet + 'ems/getOrdersByUserId', //邮寄列表
    getEmsResult: GLOBAL_CONFIG.internet + 'ems/result_query', //获取结果
    cancelOrder: GLOBAL_CONFIG.internet + 'ems/cancle_order', //取消订单
    cancelPickUp: GLOBAL_CONFIG.internet + 'ems/cancle_pick_up', //取消上门揽件
    changeTime: GLOBAL_CONFIG.internet + 'ems/change_appointment_time', //修改预约时间
    getTrace: GLOBAL_CONFIG.internet + 'ems/jdpt_trace', //获取轨迹
    saveOrderInfo: GLOBAL_CONFIG.internet + 'ems/saveOrderInfo', //保存参数
  },
  // 部委共享核验
  bwgx: {
    getBwgxInfo: GLOBAL_CONFIG.internet + 'GxjkController/getBwgxInfo',
  },
  //可视化查询
  visualQuery: {
    visualQuerysave: GLOBAL_CONFIG.internet + 'visualQueryRecordController/save',
    visualQueryupdate: GLOBAL_CONFIG.internet + 'visualQueryRecordController/updateRzztById',
    visualQueryfind: GLOBAL_CONFIG.internet + 'visualQueryRecordController/findById',
  },
};
