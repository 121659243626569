<template>
  <div class="flex">
    <div class="flex1">
      <el-form-item prop="zlProvince" :rules="zlConfigInit.rules">
        <el-select v-model="zlData.zlProvince" placeholder="请选择省" size="small" filterable @change="getValue">
          <el-option
            v-for="(item, index) in areaData"
            :key="item.id + index"
            :label="item.title"
            :value="item[zlConfigInit.zlKey]"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="flex1">
      <el-form-item prop="zlCity" :rules="zlConfigInit.rules">
        <el-select v-model="zlData.zlCity" placeholder="请选择市" size="small" filterable @change="getValue">
          <el-option
            v-for="(item, index) in cityData"
            :key="item.id + index"
            :label="item.title"
            :value="item[zlConfigInit.zlKey]"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="flex1">
      <el-form-item prop="zlArea" :rules="zlConfigInit.rules">
        <el-select v-model="zlData.zlArea" placeholder="请选择区县" size="small" filterable @change="getValue">
          <el-option
            v-for="(item, index) in countyData"
            :key="item.id + index"
            :label="item.title"
            :value="item[zlConfigInit.zlKey]"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="flex1" v-if="!zlConfigInit.hideZl">
      <el-form-item prop="zl" :rules="zlConfigInit.rules">
        <el-input v-model="zlData.zl" :disabled="zlConfigInit.disabled" placeholder="请填写详细信息"></el-input>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCookie } from '@iebdc/utils/cookies';
import utils from '@iebdc/utils/utils';
export default {
  model: {
    prop: 'zlData',
    event: 'update',
  },
  props: {
    zlData: {},
    zlConfig: {
      type: Object,
      default() {
        return {
          zlKey: 'title',
          rules: [],
          hideZl: false,
          disabled: false,
        };
      },
    },
  },
  data() {
    return {
      areaData: JSON.parse(localStorage.getItem('finalAreaDataLocal')),
      cityData: [],
      countyData: [],
      zlConfigInit: {},
    };
  },
  created() {
    this.init();
    this.$bus.$on('selectedZlData', (msg) => {
      this.init();
    });
  },
  mounted() {},
  beforeDestroy() {
    this.$bus.$off('selectedZlData');
  },
  watch: {
    'zlData.zlProvince'(item) {
      if (item) {
        this.cityData = this.getValue(this.areaData, item);
        this.$emit('update', this.zlData);
      }
    },
    'zlData.zlCity'(item) {
      if (item) {
        this.countyData = this.getValue(this.cityData, item);
        this.$emit('update', this.zlData);
      }
    },
    'zlData.zlArea'(item) {
      if (item) {
        this.$emit('update', this.zlData);
      }
    },
    'zlData.zl'(item) {
      if (item) {
        this.$emit('update', this.zlData);
      }
    },
  },
  computed: {
    ...mapGetters(['selectedAreaCode']),
  },
  methods: {
    init() {
      let zlConfigInit = {
        zlKey: 'title',
        rules: [],
        hideZl: false,
        disabled: false,
      };
      this.zlConfigInit = Object.assign(zlConfigInit, this.zlConfig);
      if (this.zlConfigInit.zlKey === 'code') {
        if (!this.zlData.zl) {
          // this.zlData.zlProvince = getCookie('selectedShengCode') || this.zlData.zlProvince;
          // this.cityData = this.getValue(this.areaData, this.zlData.zlProvince);
          // this.zlData.zlCity = getCookie('selectedShiCode') || this.zlData.zlCity;
          // this.countyData = this.getValue(this.cityData, this.zlData.zlCity);
          // this.zlData.zlArea = getCookie('selectedAreaCode') || this.zlData.zlArea;
          this.zlData.zlProvince =
            (this.$store.state.dashboard && this.$store.state.dashboard.selectedShengCode) ||
            this.zlData.zlProvince ||
            window.CONFIG.code;
          this.zlData.zlCity =
            (this.$store.state.dashboard && this.$store.state.dashboard.selectedShiCode) || this.zlData.zlCity;
          this.zlData.zlArea =
            (this.$store.state.dashboard && this.$store.state.dashboard.selectedAreaCode) || this.zlData.zlArea;
          this.cityData = this.getValue(this.areaData, this.zlData.zlProvince);
          this.countyData = this.getValue(this.cityData, this.zlData.zlCity);
        }
      } else {
        if (!this.zlData.zl) {
          this.zlData.zlProvince = utils.decodeUnicode(getCookie('selectedShengTitle')) || this.zlData.zlProvince;
          this.cityData = this.getValue(this.areaData, this.zlData.zlProvince);
          this.zlData.zlCity = utils.decodeUnicode(getCookie('selectedShiTitle')) || this.zlData.zlCity;
          this.countyData = this.getValue(this.cityData, this.zlData.zlCity);
          this.zlData.zlArea = utils.decodeUnicode(getCookie('selectedAreaTitle')) || this.zlData.zlArea;
        } else {
          if (!this.zlData.zlArea) {
            this.zlData.zlArea = getCookie('selectedAreaTitle');
          }
        }
      }
    },
    //遍历array获取值
    getValue(array, item) {
      console.log(item);
      if (!array || !array.length) return;
      let data = [];
      for (let i = 0; i < array.length; i++) {
        let key = this.zlConfigInit.zlKey ? this.zlConfigInit.zlKey : 'title';
        if (item === array[i][key]) {
          data = array[i].childrens;
        }
      }
      if (data.length > 1) {
        //多条数据时候默认展示对应市的区
        data.forEach((item, index) => {
          if (item.code === this.selectedAreaCode) {
            this.zlData.zlArea = this.zlConfigInit.zlKey === 'code' ? data[index].code : data[index].title;
          }
        });
      } else {
        //1条数据时候默认展示当前市的第一个区
        this.zlData.zlArea = data[0].code;
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex1:not(.areaShow) {
  flex: 1;
  padding-right: 10px;
}
.flex1:last-child {
  flex: 4;
  padding-right: 0px;
}
.areaShow {
  width: 25%;
}
.noShow {
  display: none;
}
.el-select {
  width: 100%;
}
/deep/ .el-form-item {
  margin-bottom: 0px !important;
}
</style>
