// 姓名验证
export const validateName = (rule, value, callback) => {
  const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
  if (!value) {
    return callback(new Error('请填写姓名'));
  } else if (!reg.test(value)) {
    return callback(new Error('请填写正确的姓名'));
  }
  callback();
};
// 身份证号验证
export const validateZjh = (func, tip = '请输入您的身份证号') => {
  return (rule, value, callback) => {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!value) {
      return callback(new Error(tip));
    } else if (!reg.test(value)) {
      return callback(new Error('格式有误'));
    } else {
      if (typeof func === 'function') {
        func(value);
      }
      callback();
    }
  };
};
export const validatePhone = async (rule, value, callback) => {
  const re =
    /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[01235689]))\d{8}$/;
  if (!value) {
    return callback(new Error('请输入电话号码'));
  }
  if (!re.test(value)) {
    return callback(new Error('请输入合法的电话号码'));
  }
  callback();
};
export const validate = {
  // 必填
  required: {
    required: true,
    message: '必填',
  },
};
